import {BoxGeometry, Mesh, MeshLambertMaterial} from 'three'
import {Color} from 'three/src/math/Color'
import Game from '../game'
import {hexy} from '../../util/hexy'
import Entity from '../entity'
import {LineGeometry} from 'three/examples/jsm/lines/LineGeometry'
import {LineMaterial} from 'three/examples/jsm/lines/LineMaterial'
import {Line2} from 'three/examples/jsm/lines/Line2'
import Movable from './movable'

// it's necessary to keep the same number of elements in buffered attributes
const path_line_segments = 25
const geometry = new BoxGeometry()


export default class Shard extends Entity {
    private path_line: Line2

    private rotation_x = Math.random() * 0.02
    private rotation_y = Math.random() * 0.02
    private rotation_z = Math.random() * 0.02

    model: Mesh
    movable: Movable

    constructor(public name: string,
                i: number,
                j: number,
                public color: Color,
                game: Game) {
        super(game)

        const material = new MeshLambertMaterial({color})
        this.model = new Mesh(geometry, material)
        this.model.castShadow = true
        this.model.userData.shard = this
        this.movable = new Movable(this.model, i, j, this, 1)

        const line_geometry = new LineGeometry()
        const line_material = new LineMaterial({
            color: color.getHex(),
            linewidth: 3,
            vertexColors: false,
            worldUnits: false,
            dashed: true,
            dashSize: 1,
            gapSize: 2,
            dashScale: 4,
            alphaToCoverage: false,
        })
        line_material.resolution.set(window.innerWidth, window.innerHeight)

        this.path_line = new Line2(line_geometry, line_material)
        this.path_line.scale.set(1, 1, 1)
        this.path_line.visible = false
    }

    init() {
        this.movable.init()
        this.game.events.register_object.emit(this.path_line)
    }

    animate() {
        this.model.rotation.x += this.rotation_x
        this.model.rotation.y += this.rotation_y
        this.model.rotation.z += this.rotation_z

        this.movable.animate()

        if (this.movable.move_i !== this.movable.i || this.movable.move_j !== this.movable.j || this.movable.animated) {
            this.path_line.visible = true
            let i = this.movable.i, j = this.movable.j
            let {hx, hy} = hexy(i, j)
            // seems wasteful to do all the calculations and allocations that happen here and in the subsequent method calls
            // if performance every becomes an actual concern, we could make this almost no allocations
            // probably better in a separate reusable line class
            const points: number[] = [
                this.model.position.x, this.model.position.y, this.game.world.terrain.getHeight(this.model.position.x, this.model.position.y) + 1,
                hx, hy, this.game.world.terrain.getHeight(hx, hy) + 1
            ]
            let path_index = this.movable.path_index
            while (points.length < path_line_segments * 3 && this.movable.path.length > path_index) {
                ({i, j} = this.movable.path[path_index]);
                ({hx, hy} = hexy(i, j))
                points.push(hx, hy, this.game.world.terrain.getHeight(hx, hy) + 1)
                path_index++
            }
            const leftover_height = points[points.length - 1] - 1
            while (points.length < path_line_segments * 3) {
                points.push(hx, hy, leftover_height)
            }
            this.path_line.geometry.setPositions(points)
            this.path_line.computeLineDistances()
        } else {
            this.path_line.visible = false
        }
    }

    tick() {
        this.movable.checkMove()
    }
}