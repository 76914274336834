import {EffectComposer} from 'three/examples/jsm/postprocessing/EffectComposer'
import {PCFSoftShadowMap, WebGLRenderer} from 'three'
import Game from '../game/game'
import {RenderPass} from 'three/examples/jsm/postprocessing/RenderPass'

export default class RendererManager {
    private renderer = new WebGLRenderer({antialias: true})
    private composer = new EffectComposer(this.renderer)

    constructor(game: Game) {
        this.renderer.setClearColor(0x000000)
        this.renderer.shadowMap.enabled = true
        this.renderer.shadowMap.type = PCFSoftShadowMap

        const passes = [
            new RenderPass(game.scene_manager.scene, game.camera_manager.camera, undefined, undefined, undefined),
            game.selections.outline_pass,
        ]
        passes.forEach(pass => this.composer.addPass(pass))

        this.resize(window.innerWidth, window.innerHeight)

        game.events.resize.on(({width, height}) => this.resize(width, height))
    }

    init() {
        document.getElementById('renderer')?.appendChild(this.renderer.domElement)
    }

    private resize(width: number, height: number) {
        this.renderer.setSize(width, height)
        this.composer.setSize(width, height)
    }

    render() {
        this.composer.render()
    }
}