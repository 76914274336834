import {CylinderGeometry, Mesh, MeshLambertMaterial} from 'three'
import * as BufferGeometryUtils from 'three/examples/jsm/utils/BufferGeometryUtils.js'
import Game from '../game'
import Entity from '../entity'
import Movable from './movable'

const geometries = []

const trunk_height = 10
const trunk_geometry = new CylinderGeometry(0.2, 1, trunk_height, 6)
trunk_geometry.translate(0, trunk_height / 2, 0)
trunk_geometry.rotateX(Math.PI / 2)
geometries.push(trunk_geometry)

const big_branch_length = 5
const big_branch_geometry = new CylinderGeometry(0.25, 0.5, big_branch_length, 3)
big_branch_geometry.translate(0, big_branch_length / 2, 0)
big_branch_geometry.rotateX(Math.PI / 12)
big_branch_geometry.translate(0, 0, 5)
geometries.push(big_branch_geometry)

const medium_branch_length = 3
const medium_branch_geometry = new CylinderGeometry(0.1, 0.2, medium_branch_length, 3)
medium_branch_geometry.translate(0, medium_branch_length / 2, 0)
medium_branch_geometry.rotateX(Math.PI / 4)
medium_branch_geometry.translate(0, 0, 7)
medium_branch_geometry.rotateZ(Math.PI * 3 / 4)
geometries.push(medium_branch_geometry)

const small_branch_length = 2
const small_branch_geometry = new CylinderGeometry(0.1, 0.15, small_branch_length, 3)
small_branch_geometry.translate(0, small_branch_length / 2, 0)
small_branch_geometry.rotateX(Math.PI / 3)
small_branch_geometry.translate(0, 0, 8.5)
small_branch_geometry.rotateZ(Math.PI * 3 / 2)
geometries.push(small_branch_geometry)

const tree_geometry = BufferGeometryUtils.mergeBufferGeometries(geometries)

const material = new MeshLambertMaterial({color: '#4f3800'})

export default class OakTree extends Entity {
    private movable: Movable
    private model: Mesh
    private spin_time = 10000 + Math.random() * 15000
    private time_offset = Math.random() * this.spin_time

    constructor(public name: string,
                i: number,
                j: number,
                game: Game) {
        super(game)

        this.model = new Mesh(tree_geometry, material)
        this.model.castShadow = true
        this.model.userData.selectable = this

        this.movable = new Movable(this.model, i, j, this, -1)
    }

    init() {
        this.movable.init()
    }

    animate() {
        this.movable.animate()
        const rotate_time = (this.game.current_time + this.time_offset) % this.spin_time
        this.model.rotation.set(0, 0, Math.PI * 2 * rotate_time / this.spin_time)
    }

    tick() {
    }
}