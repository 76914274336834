class StatsPanel {
    dom = document.createElement('canvas')
    private context = this.dom.getContext('2d')!!
    private min = Infinity
    private max = 0

    private PR = Math.round(window.devicePixelRatio || 1)
    private WIDTH = 80 * this.PR
    private HEIGHT = 48 * this.PR
    private TEXT_X = 3 * this.PR
    private TEXT_Y = 2 * this.PR
    private GRAPH_X = 3 * this.PR
    private GRAPH_Y = 15 * this.PR
    private GRAPH_WIDTH = 74 * this.PR
    private GRAPH_HEIGHT = 30 * this.PR

    constructor(private name: string, private fg: string, private bg: string) {
        this.dom.width = this.WIDTH
        this.dom.height = this.HEIGHT
        this.dom.style.cssText = 'width:80px;height:48px'

        this.context.font = 'bold ' + (9 * this.PR) + 'px Helvetica,Arial,sans-serif'
        this.context.textBaseline = 'top'

        this.context.fillStyle = bg
        this.context.fillRect(0, 0, this.WIDTH, this.HEIGHT)

        this.context.fillStyle = fg
        this.context.fillText(name, this.TEXT_X, this.TEXT_Y)
        this.context.fillRect(this.GRAPH_X, this.GRAPH_Y, this.GRAPH_WIDTH, this.GRAPH_HEIGHT)

        this.context.fillStyle = bg
        this.context.globalAlpha = 0.9
        this.context.fillRect(this.GRAPH_X, this.GRAPH_Y, this.GRAPH_WIDTH, this.GRAPH_HEIGHT)
    }

    update(value: number, maxValue: number) {
        this.min = Math.min(this.min, value)
        this.max = Math.max(this.max, value)

        this.context.fillStyle = this.bg
        this.context.globalAlpha = 1
        this.context.fillRect(0, 0, this.WIDTH, this.GRAPH_Y)
        this.context.fillStyle = this.fg
        this.context.fillText(Math.round(value) + ' ' + this.name + ' (' + Math.round(this.min) + '-' + Math.round(this.max) + ')', this.TEXT_X, this.TEXT_Y)

        this.context.drawImage(this.dom, this.GRAPH_X + this.PR, this.GRAPH_Y, this.GRAPH_WIDTH - this.PR, this.GRAPH_HEIGHT, this.GRAPH_X, this.GRAPH_Y, this.GRAPH_WIDTH - this.PR, this.GRAPH_HEIGHT)

        this.context.fillRect(this.GRAPH_X + this.GRAPH_WIDTH - this.PR, this.GRAPH_Y, this.PR, this.GRAPH_HEIGHT)

        this.context.fillStyle = this.bg
        this.context.globalAlpha = 0.9
        this.context.fillRect(this.GRAPH_X + this.GRAPH_WIDTH - this.PR, this.GRAPH_Y, this.PR, Math.round((1 - (value / maxValue)) * this.GRAPH_HEIGHT))
    }

    reset() {
        this.min = Infinity
        this.max = 0
    }
}

export class Stats {
    dom = document.createElement('div')
    private beginTime = performance.now()
    private prevTime = this.beginTime
    private frames = 0

    private fpsPanel = this.addPanel(new StatsPanel('FPS', '#0ff', '#002'))
    private msPanel = this.addPanel(new StatsPanel('MS', '#0f0', '#020'))

    constructor() {
        this.dom.style.cssText = 'position:fixed;top:0;left:0;opacity:0.9;z-index:10000'
    }

    private addPanel(panel: StatsPanel) {
        this.dom.appendChild(panel.dom)
        panel.dom.style.display = 'block'
        return panel
    }

    begin() {
        this.beginTime = performance.now()
    }

    end() {
        this.frames++

        const time = performance.now()

        this.msPanel.update(time - this.beginTime, 200)

        if (time >= this.prevTime + 1000) {

            this.fpsPanel.update((this.frames * 1000) / (time - this.prevTime), 100)

            this.prevTime = time
            this.frames = 0
        }

        return time
    }

    update() {
        this.beginTime = this.end()
    }

    reset() {
        this.fpsPanel.reset()
        this.msPanel.reset()
    }
}