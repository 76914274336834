import {OutlinePass} from 'three/examples/jsm/postprocessing/OutlinePass'
import {Color, Intersection, Object3D, Raycaster, Vector2} from 'three'
import {yxeh} from '../util/hexy'
import TerrainHover from '../game/entities/terrain_hover'
import Game from '../game/game'

function get_parent_object(object: Object3D): Object3D {
    if (object.parent && object.parent.type === 'Group') {
        return object.parent
    } else {
        return object
    }
}

export default class Selections {
    private raycaster = new Raycaster()
    private mouse_active = false
    private mouse = new Vector2()

    private selectable_objects: Object3D[] = []
    private mouse_intersections: Intersection[] = []

    outline_pass: OutlinePass

    private terrain_hover: TerrainHover

    constructor(private game: Game) {
        this.outline_pass = new OutlinePass(new Vector2(window.innerWidth, window.innerHeight), game.scene_manager.scene, game.camera_manager.camera)
        this.outline_pass.edgeStrength = 2
        this.outline_pass.edgeGlow = 0
        this.outline_pass.edgeThickness = 1
        this.outline_pass.visibleEdgeColor = new Color(0xFFFFFFFF)
        this.outline_pass.hiddenEdgeColor = new Color(0x00000000)

        this.terrain_hover = new TerrainHover(game)

        game.events.mouse_move.on(e => this.mouseMove(e))
        game.events.mouse_down.on(e => this.mouseDown(e))
        game.events.mouse_up.on(e => this.mouseUp(e))
        game.events.mouse_out.on(e => this.mouseOut(e))
        game.events.register_object.on(e => this.registerObject(e))
        game.events.unregister_object.on(e => this.unregisterObject(e))
    }

    init() {
        this.game.events.register_object.emit(this.terrain_hover.model)
    }

    private get_mouse_intersection(): Intersection | null {
        this.raycaster.setFromCamera(this.mouse, this.game.camera_manager.camera)
        this.mouse_intersections.length = 0
        this.raycaster.intersectObjects(this.selectable_objects, true, this.mouse_intersections)
        if (this.mouse_intersections.length > 0) {
            return this.mouse_intersections[0]
        }
        return null
    }


    update() {
        this.terrain_hover.model.visible = false
        this.outline_pass.selectedObjects.length = 0
        if (this.mouse_active) {
            const intersection = this.get_mouse_intersection()
            if (intersection) {
                const object = get_parent_object(intersection.object)
                if (object.userData.shard) {
                    this.outline_pass.selectedObjects.push(object)
                } else if (object.userData.terrain) {
                    this.terrain_hover.update(intersection.point)
                    // this.outline_pass.selectedObjects.push(object)
                } else if (object.userData.selectable) {
                    this.outline_pass.selectedObjects.push(object)
                }
            }
        }
    }

    private mouseMove(event: MouseEvent) {
        this.mouse_active = true
        // calculate mouse position in normalized device coordinates
        // (-1 to +1) for both components
        this.mouse.x = (event.clientX / window.innerWidth) * 2 - 1
        this.mouse.y = -(event.clientY / window.innerHeight) * 2 + 1
    }

    private mouseDown(event: MouseEvent) {
        this.mouseMove(event)
        const intersection = this.get_mouse_intersection()
        if (intersection) {
            const object = get_parent_object(intersection.object)
            if (object.userData.shard) {
                this.game.events.select_shard.emit(object.userData.shard)
            } else if (object.userData.terrain) {
                const point = intersection.point
                this.game.events.move_shard.emit(yxeh(point.x, point.y))
            } else if (object.userData.selectable) {
                // TODO
            }
        }
    }

    private mouseUp(_event: MouseEvent) {
    }

    private mouseOut(_event: MouseEvent) {
        this.mouse_active = false
    }

    private registerObject(object: Object3D) {
        if (object.userData.shard || object.userData.terrain || object.userData.selectable) {
            this.selectable_objects.push(object)
        }
    }

    private unregisterObject(object: Object3D) {
        const i = this.selectable_objects.indexOf(object)
        if (i >= 0) {
            this.selectable_objects.splice(i, 1)
        }
    }
}