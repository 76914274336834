import {AmbientLight, DirectionalLight} from 'three'
import Game from '../game'
import Entity from '../entity'


export default class Sun extends Entity {
    private ambient = new AmbientLight(0x666666)

    private light_directional = new DirectionalLight(0xffffff, 0.5)

    private vertical_cycle_time = 20 * 60000
    private vertical_minimum_angle = Math.PI / 12

    private horizontal_cycle_time = 5 * 60000

    private distance_cycle_time = 12 * 60000
    private distance_minimum = 20
    private distance_range = 80

    private time_offset = Math.random() * 60 * 60000

    constructor(game: Game) {
        super(game)

        this.light_directional.castShadow = true
        this.light_directional.shadow.mapSize.width = 2048  // default
        this.light_directional.shadow.mapSize.height = 2048 // default
        this.light_directional.shadow.camera.left = -50
        this.light_directional.shadow.camera.right = 50
        this.light_directional.shadow.camera.bottom = -50
        this.light_directional.shadow.camera.top = 50
        this.light_directional.shadow.camera.far = 400
    }

    init() {
        this.game.events.register_object.emit(this.ambient)
        this.game.events.register_object.emit(this.light_directional)
        // this.game.events.register_object.emit(new CameraHelper(this.light_directional.shadow.camera))
    }

    animate() {
        const time = this.game.current_time + this.time_offset

        const vertical_ratio = Math.abs((time % (2 * this.vertical_cycle_time)) - this.vertical_cycle_time) / this.vertical_cycle_time
        const vertical_angle = vertical_ratio * (Math.PI - 2 * this.vertical_minimum_angle) + this.vertical_minimum_angle

        const horizontal_ratio = (time % this.horizontal_cycle_time) / this.horizontal_cycle_time
        const horizontal_angle = horizontal_ratio * 2 * Math.PI

        const distance_ratio = Math.abs((time % (2 * this.distance_cycle_time)) - this.distance_cycle_time) / this.distance_cycle_time
        const distance = this.distance_minimum + distance_ratio * this.distance_range

        const z = Math.sin(vertical_angle) * distance
        const horizontal_distance = Math.cos(vertical_angle) * distance
        const x = Math.cos(horizontal_angle) * horizontal_distance
        const y = Math.sin(horizontal_angle) * horizontal_distance

        this.light_directional.position.set(
            this.game.world.target_shard.model.position.x + x,
            this.game.world.target_shard.model.position.y + y,
            this.game.world.target_shard.model.position.z + z
        )
        this.light_directional.target = this.game.world.target_shard.model
    }

    tick() {
    }
}