import {Fog, PerspectiveCamera, Vector3} from 'three'
import Vector3ConstantRatioAnimation from '../util/vector3_constant_ratio_animation'
import Game from '../game/game'


export default class CameraManager {
    private width: number
    private height: number

    camera: PerspectiveCamera

    private position_animation: Vector3ConstantRatioAnimation
    private target_animation: Vector3ConstantRatioAnimation

    private horizontal_angle = 0.5 * Math.PI
    private vertical_angle = 0.25 * Math.PI
    private distance = 15

    private fog = new Fog('#005d60', 90, 100)

    constructor(game: Game) {
        this.width = window.innerWidth
        this.height = window.innerHeight

        this.camera = new PerspectiveCamera(50, this.width / this.height, 0.1, 140)
        this.position_animation = new Vector3ConstantRatioAnimation(0, 0, 0, 10, game)
        this.target_animation = new Vector3ConstantRatioAnimation(0, 0, 0, 10, game)

        game.scene_manager.scene.background = this.fog.color
        game.scene_manager.scene.fog = this.fog

        game.events.resize.on(({width, height}) => this.resize(width, height))
        game.events.mouse_wheel.on(e => this.wheel(e))
        game.events.mouse_middle_drag.on(e => this.drag(e))
    }

    update(target: Vector3) {
        const z = Math.sin(this.vertical_angle) * this.distance
        const horizontal_distance = Math.cos(this.vertical_angle) * this.distance

        this.position_animation.setTarget(
            target.x - Math.cos(this.horizontal_angle) * horizontal_distance,
            target.y - Math.sin(this.horizontal_angle) * horizontal_distance,
            target.z + z
        )
        this.position_animation.animate()
        this.target_animation.setTarget(target.x, target.y, target.z)
        this.target_animation.animate()

        this.camera.position.copy(this.position_animation.position)
        this.camera.lookAt(this.target_animation.position)

        const x_distance = this.target_animation.position.x - this.position_animation.position.x
        const y_distance = this.target_animation.position.y - this.position_animation.position.y
        const distance = Math.sqrt(x_distance * x_distance + y_distance * y_distance)
        this.fog.near = 80 + distance
        this.fog.far = this.fog.near + 10
    }

    private drag(event: MouseEvent) {
        this.horizontal_angle -= event.movementX / this.width * Math.PI * 2 * 1.5
        // quarter screen drag ~= 80 degree vertical rotation
        this.vertical_angle = Math.max(Math.PI * 0.1, Math.min(Math.PI * 0.49,
            this.vertical_angle + event.movementY / this.height * Math.PI))
    }

    private wheel(event: WheelEvent) {
        this.distance = Math.max(3.5, Math.min(35, this.distance + event.deltaY * 0.025))
    }

    private resize(width: number, height: number) {
        this.camera.aspect = width / height
        this.camera.updateProjectionMatrix()

        this.width = width
        this.height = height
    }
}