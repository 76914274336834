export type Comparator<T> = (a: T, b: T) => number;

function getLeft(i: number) {
    return 2 * i + 1
}

function getRight(i: number) {
    return 2 * i + 2
}

function getParent(i: number) {
    if (i === 0) {
        return -1
    }
    return Math.floor((i - 1) / 2)
}

function swap<T>(array: T[], a: number, b: number) {
    const temp = array[a]
    array[a] = array[b]
    array[b] = temp
}

export default class MinHeap<T> {
    private list: T[] = []

    constructor(public compare: Comparator<T>) {
    }

    /**
     * Add an element to the heap.
     */
    insert(element: T) {
        this.list.push(element)
        this.floatUp(this.list.length - 1)
    }

    /**
     * Remove and return the minimum element in the heap.
     */
    extractMinimum() {
        if (!this.list.length) {
            return undefined
        }
        if (this.list.length === 1) {
            return this.list.shift()
        }
        const min = this.list[0]
        this.list[0] = this.list.pop()!!
        this.sinkDown(0)
        return min
    }

    /**
     * Move an element down the tree to the right spot.
     */
    sinkDown(i: number) {
        const l = getLeft(i)
        const r = getRight(i)
        let smallest = i
        if (l < this.list.length && this.compare(this.list[l], this.list[i]) < 0) {
            smallest = l
        }
        if (r < this.list.length && this.compare(this.list[r], this.list[smallest]) < 0) {
            smallest = r
        }
        if (smallest !== i) {
            swap(this.list, i, smallest)
            this.sinkDown(smallest)
        }
    }

    /**
     * Move an element up the tree to the right spot.
     */
    floatUp(n: number) {
        let parent = getParent(n)
        while (parent >= 0 && this.compare(this.list[n], this.list[parent]) < 0) {
            swap(this.list, n, parent)
            n = parent
            parent = getParent(n)
        }
    }

    floatUpElement(element: T) {
        this.floatUp(this.list.indexOf(element))
    }

    clear() {
        this.list.length = 0
    }

    isEmpty() {
        return !this.list.length
    }

    size() {
        return this.list.length
    }
}