import {BufferAttribute, BufferGeometry, Mesh, MeshLambertMaterial} from 'three'
import {SimplexNoise} from 'three/examples/jsm/math/SimplexNoise'
import {hex_points, hex_points_indices, hexy, ij_distance, ij_offsets} from '../util/hexy'
import Entity from './entity'
import KDTree from '../util/kd_tree'

const terrain_mat = new MeshLambertMaterial({color: '#666666'})

const cell_size = 8

/**
 * The hex location at the center of a cell.
 */
function cell_center(ci: number, cj: number) {
    return {
        i: ci * 2 * cell_size + ci + cj * cell_size,
        j: cj * cell_size + cj - ci * cell_size
    }
}

const check_ij_offsets = [
    {i: 0, j: 0},
    {i: -1, j: 0},
    {i: 0, j: -1},
    {i: -1, j: -1},
]

/**
 * The cell coordinates containing a hex.
 */
function cij_from_ij(i: number, j: number) {
    const ii = 2 * cell_size + 1
    const ij = -cell_size
    const ji = cell_size
    const jj = cell_size + 1

    // y = ij/ii * x + c - "horizontal" line
    // y = jj/ji * x - "vertical" line

    const c = j - ij / ii * i

    // jj/ji * x = ij/ii * x + c
    // jj/ji * x - ij/ii * x = c
    // (jj * ii - ij * ji) / (ji * ii) * x = c
    // x = (ji * ii) / (jj * ii - ij * ji) * c
    const intersection_i = (ji * ii) / (jj * ii - ij * ji) * c
    const intersection_j = jj / ji * intersection_i

    let base_ci = Math.ceil((i - intersection_i) / ii)
    let base_cj = Math.ceil(intersection_j / jj)

    for (let offset of check_ij_offsets) {
        let ci = base_ci + offset.i
        let cj = base_cj + offset.j
        let center = cell_center(ci, cj)
        if (ij_distance(i, j, center.i, center.j) <= cell_size) {
            return {ci, cj}
        }
    }
    throw new Error(`Could not determine cell_ij from target_ij (${i}, ${j})`)
    // return {cell_i: base_cell_i, cell_j: base_cell_j}
}

export type CellPoint = [number, number]

class Cell {
    model: Mesh
    mark = true

    constructor(public ci: number,
                public cj: number,
                terrain: Terrain) {
        const center = cell_center(ci, cj)

        let mxy = hexy(center.i, center.j)

        const vertices: number[] = []
        for (let distance = 0; distance <= cell_size; distance++) {
            for (let offset of ij_offsets(distance)) {
                const {hx, hy} = hexy(center.i + offset.i, center.j + offset.j)
                for (let hex_point_index of hex_points_indices) {
                    const hex_point = hex_points[hex_point_index]
                    const hex_x = hx + hex_point.x
                    const hex_y = hy + hex_point.y
                    vertices.push(hex_x - mxy.hx, hex_y - mxy.hy, terrain.getHeight(hex_x, hex_y))
                }
            }
        }

        const geometry = new BufferGeometry()
        geometry.setAttribute('position', new BufferAttribute(new Float32Array(vertices), 3))
        geometry.computeVertexNormals()
        geometry.computeBoundingBox()

        this.model = new Mesh(geometry, terrain_mat)
        this.model.receiveShadow = true
        this.model.position.set(mxy.hx, mxy.hy, 0)
        this.model.userData.terrain = this
    }

    dispose() {
        this.model.geometry.dispose()
    }
}

export default class Terrain extends Entity {
    private noise = new SimplexNoise()
    private big_noise = new SimplexNoise()
    private cells: Cell[] = []
    private cell_tree = new KDTree<Cell, 2, CellPoint>(2)

    private last_ci = 0
    private last_cj = 0

    getHeight(x: number, y: number) {
        return this.big_noise.noise(x / 100, y / 100) * 6 + this.noise.noise(x / 15, y / 15) * 2
    }

    init() {
        let cij = cij_from_ij(
            this.game.world.target_shard.movable.i,
            this.game.world.target_shard.movable.j
        )
        this.updateCells(cij.ci, cij.cj)
    }

    animate(): void {
    }

    tick(): void {
        let cij = cij_from_ij(
            this.game.world.target_shard.movable.i,
            this.game.world.target_shard.movable.j
        )
        if (cij.ci !== this.last_ci || cij.cj !== this.last_cj) {
            this.updateCells(cij.ci, cij.cj)
        }
    }

    private updateCells(ci: number, cj: number) {
        for (let cell of this.cells) {
            cell.mark = false
        }

        for (let distance = 0; distance <= 4; distance++) {
            for (let {i, j} of ij_offsets(distance)) {
                const cell = this.cell_tree.getElementAt([ci + i, cj + j])
                if (cell) {
                    cell.mark = true
                } else {
                    const new_cell = new Cell(ci + i, cj + j, this)
                    this.game.events.register_object.emit(new_cell.model)
                    this.cells.push(new_cell)
                    this.cell_tree.insert([ci + i, cj + j], new_cell)
                }
            }
        }

        for (let index = this.cells.length - 1; index >= 0; index--) {
            const cell = this.cells[index]
            if (!cell.mark) {
                this.cell_tree.remove([cell.ci, cell.cj], cell)
                this.cells.splice(index, 1)
                this.game.events.unregister_object.emit(cell.model)
                cell.dispose()
            }
        }

        this.last_ci = ci
        this.last_cj = cj
    }
}