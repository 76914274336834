import React from 'react'
import ReactDOM from 'react-dom'
import './index.scss'
import {Object3D} from 'three'
import {DnfwsUi} from './ui/dnfws_ui'
import {updateShardStyles} from './ui/shard_styles'
import Game from './game/game'


Object3D.DefaultUp.set(0, 0, 1)

const game = new Game()

updateShardStyles(game.world.shards)

ReactDOM.render(
    <React.StrictMode>
        <div id="renderer"/>
        <div id="stats"/>
        <DnfwsUi game={game}/>
    </React.StrictMode>,
    document.getElementById('root')
)

game.start()
