import {Scene} from 'three'
import Game from '../game/game'

export default class SceneManager {
    scene = new Scene()

    constructor(private game: Game) {
        game.events.register_object.on(e => this.scene.add(e))
        game.events.unregister_object.on(e => this.scene.remove(e))
    }
}