// help from: http://blog.ruslans.com/2011/02/hexagonal-grid-math.html
// - we don't alternate column heights like that page

import {Vector2} from 'three'

export const hex_radius = 1.5 // R

const offset_x_ratio = Math.cos(Math.PI / 6)
const offset_y_ratio = Math.sin(Math.PI / 6)

const center_to_side = offset_x_ratio * hex_radius
const center_to_center = 2 * offset_x_ratio * hex_radius

const tile_width = 3 / 2 * hex_radius // S
const tile_height = center_to_center // H

/**
 * Calculate euclidean coordinates of a hex location.
 *
 * This is the center of the hex.
 */
export function hexy(i: number, j: number) {
    const hex_offset_h = i * center_to_center
    const hex_offset_x = offset_x_ratio * hex_offset_h
    const hex_offset_y = offset_y_ratio * hex_offset_h
    const base_y = j * center_to_center
    return {
        hx: hex_offset_x,
        hy: base_y + hex_offset_y
    }
}

/**
 * Calculate hex location from euclidean coordinates.
 */
export function yxeh(hx: number, hy: number) {
    const it = Math.floor((hx + hex_radius) / tile_width)
    const jt = Math.floor((hy + center_to_side - it * center_to_side) / tile_height)
    const xt = hx + hex_radius - it * tile_width
    const yt = hy + center_to_side - jt * tile_height - it * center_to_side
    if (xt > hex_radius * Math.abs(0.5 - yt / tile_height)) {
        return {
            i: it,
            j: jt,
        }
    } else {
        return {
            i: it - 1,
            j: jt + (yt > tile_height / 2 ? 1 : 0),
        }
    }
}

/**
 * The euclidean offsets from the center of a hex where the 6 corners should be.
 */
export const hex_points: Vector2[] = []
for (let a = 0; a < 2 * Math.PI; a += 2 * Math.PI / 6) {
    hex_points.push(new Vector2(Math.cos(a) * hex_radius, Math.sin(a) * hex_radius))
}
/**
 * The indices in hex_points that make up the triangles of the hex.
 *
 * Every 3 indices give the coordinates for one of the triangles.
 */
export const hex_points_indices = [
    0, 1, 5,
    1, 2, 5,
    2, 3, 4,
    4, 5, 2,
]

/**
 * The minimum number of hexes that have to be traversed to move from one location to another.
 */
export function ij_distance(i1: number, j1: number, i2: number, j2: number) {
    const xd = i2 - i1
    const yd = j2 - j1
    if ((xd <= 0 && yd <= 0) || (xd >= 0 && yd >= 0)) {
        return Math.abs(xd) + Math.abs(yd)
    } else {
        return Math.max(Math.abs(xd), Math.abs(yd))
    }
}

const ij_directions = [
    {i: -1, j: 1},
    {i: -1, j: 0},
    {i: 0, j: -1},
    {i: 1, j: -1},
    {i: 1, j: 0},
    {i: 0, j: 1},
]
const ij_offsets_cache: { [distance: number]: { i: number, j: number }[] } = {}

/**
 * The offsets that correspond with hex cells that are a given distance away.
 *
 * Responses are cached for each distance, do not modify the results
 */
export function ij_offsets(distance: number) {
    if (ij_offsets_cache[distance]) {
        return ij_offsets_cache[distance]
    }
    const offsets = []
    let i = distance, j = 0
    if (distance === 0) {
        offsets.push({i, j})
        return offsets
    }
    for (let {i: id, j: jd} of ij_directions) {
        for (let d = 0; d < distance; d++) {
            i += id
            j += jd
            offsets.push({i, j})
        }
    }
    ij_offsets_cache[distance] = offsets
    return offsets
}