import {Object3D} from 'three'
import Vector3LinearTimeAnimation from '../../util/vector3_linear_time_animation'
import {hexy} from '../../util/hexy'
import {findPath, IJ} from '../../util/pathing'
import Entity from '../entity'

export default class Movable {
    move_i: number
    move_j: number
    path: IJ[] = []
    path_index = 1
    next_move_tick = 0
    animated = false

    private position_animation: Vector3LinearTimeAnimation

    constructor(private model: Object3D,
                public i: number,
                public j: number,
                private entity: Entity,
                private float_height: number) {
        this.move_i = i
        this.move_j = j

        this.position_animation = new Vector3LinearTimeAnimation(0, 0, 0, entity.game)
    }

    init() {
        const {hx, hy} = hexy(this.i, this.j)
        this.position_animation.set(hx, hy, this.entity.game.world.terrain.getHeight(hx, hy) + this.float_height)
        this.model.position.copy(this.position_animation.position)
        this.entity.game.world.addEntityLocation([this.i, this.j], this.entity)
        this.entity.game.events.register_object.emit(this.model)
    }

    animate() {
        this.animated = this.position_animation.animate()
        if (this.animated) {
            this.model.position.copy(this.position_animation.position)
        }
    }

    private updatePath() {
        this.path = findPath(this.entity.game.world, this.i, this.j, this.move_i, this.move_j)
        this.path_index = 1
        this.move_i = this.path[this.path.length -1].i
        this.move_j = this.path[this.path.length -1].j
    }

    checkMove() {
        if (this.next_move_tick > this.entity.game.current_tick) {
            return // not ready to move
        }
        if (this.move_i === this.i && this.move_j === this.j) {
            return // already at target
        }
        if (this.path_index >= this.path.length) {
            return // end of path
        }
        let {i, j} = this.path[this.path_index]
        if (this.entity.game.world.isLocationOccupied([i, j])) {
            this.updatePath();
        }
        if (this.path_index >= this.path.length) {
            return // end of path
        }
        ({i, j} = this.path[this.path_index])
        this.path_index++
        this.setLocation(i, j)
        this.next_move_tick = this.entity.game.current_tick + 5
    }

    setMoveTarget(i: number, j: number) {
        this.move_i = i
        this.move_j = j
        this.updatePath()
    }

    private setLocation(i: number, j: number) {
        this.entity.game.world.removeEntityLocation([this.i, this.j], this.entity)
        this.i = i
        this.j = j
        this.entity.game.world.addEntityLocation([this.i, this.j], this.entity)

        const {hx, hy} = hexy(this.i, this.j)
        this.position_animation.setTarget(
            hx,
            hy,
            this.entity.game.world.terrain.getHeight(hx, hy) + this.float_height,
            5 * this.entity.game.tick_duration,
        )
    }
}