/**
 * Based on https://github.com/ubilabs/kd-tree-javascript
 */

type OptionalNode<E, D extends number, P extends number[] & { length: D }> = KDNode<E, D, P> | null

class KDNode<E, D extends number, P extends number[] & { length: D }> {
    left: OptionalNode<E, D, P> = null
    right: OptionalNode<E, D, P> = null

    constructor(public point: P,
                public element: E,
                public dimension: number,
                public parent: OptionalNode<E, D, P>) {
    }
}

export default class KDTree<E, D extends number, P extends number[] & { length: D }> {
    root: OptionalNode<E, D, P> = null

    constructor(private dimension_count: D) {
    }

    insert(point: P, element: E) {
        const node = this.innerSearch(this.root, point)

        if (!node) {
            this.root = new KDNode(point, element, 0, null)
        } else {
            const newNode = new KDNode(point, element, (node.dimension + 1) % this.dimension_count, node)

            if (point[node.dimension] < node.point[node.dimension]) {
                node.left = newNode
            } else {
                node.right = newNode
            }
        }
    }

    private innerSearch(node: OptionalNode<E, D, P>, point: P): OptionalNode<E, D, P> {
        let parent: OptionalNode<E, D, P> = null
        while (!!node) {
            if (point[node.dimension] < node.point[node.dimension]) {
                parent = node
                node = node.left
            } else {
                parent = node
                node = node.right
            }
        }
        return parent
    }

    remove(point: P, element: E) {
        const node = this.nodeSearch(this.root, point, element)
        this.removeNode(node)
    }

    private nodeSearch(node: OptionalNode<E, D, P>, point: P, element: E): OptionalNode<E, D, P> {
        while (!!node) {
            if (node.element === element && this.pointMatch(point, node.point)) {
                return node
            }

            if (point[node.dimension] < node.point[node.dimension]) {
                node = node.left
            } else {
                node = node.right
            }
        }
        return null
    }

    private removeNode(node: OptionalNode<E, D, P>) {
        while (!!node) {
            if (!node.left && !node.right) { // leaf node
                if (!node.parent) {
                    this.root = null
                } else if (node === node.parent.left) {
                    node.parent.left = null
                } else {
                    node.parent.right = null
                }
                node = null
            } else { // not leaf node
                if (node.right) {
                    const nextNode = this.findMin(node.right, node.dimension)!!
                    node.point = nextNode.point
                    node.element = nextNode.element
                    node = nextNode
                } else {
                    const nextNode = this.findMin(node.left, node.dimension)!!
                    node.right = node.left
                    node.left = null
                    node.point = nextNode.point
                    node.element = nextNode.element
                    node = nextNode
                }
            }
        }
    }

    private findMin(node: OptionalNode<E, D, P>, dimension: number): OptionalNode<E, D, P> {
        if (!node) {
            return null
        }

        if (node.dimension === dimension) {
            if (node.left !== null) {
                return this.findMin(node.left, dimension)
            } else {
                return node
            }
        }

        const left = this.findMin(node.left, dimension)
        const right = this.findMin(node.right, dimension)
        let min = node
        if (left && left.point[dimension] < min.point[dimension]) {
            min = left
        }
        if (right && right.point[dimension] < min.point[dimension]) {
            min = right
        }
        return min
    }

    queryRange(min: P, max: P): E[] {
        const entities: E[] = []
        this.innerQuery(this.root, min, max, entities)
        return entities
    }

    private innerQuery(node: OptionalNode<E, D, P>, min: P, max: P, entities: E[]) {
        if (!node) {
            return
        }
        if (this.pointBetween(node.point, min, max)) {
            entities.push(node.element)
        }
        if (min[node.dimension] <= node.point[node.dimension]) {
            this.innerQuery(node.left, min, max, entities)
        }
        if (max[node.dimension] >= node.point[node.dimension]) {
            this.innerQuery(node.right, min, max, entities)
        }
    }

    private pointBetween(p: P, min: P, max: P) {
        for (let i = 0; i < this.dimension_count; i++) {
            const v = p[i]
            if (min[i] > v || v > max[i]) {
                return false
            }
        }
        return true
    }

    getElementAt(point: P): E | undefined {
        return this.pointSearch(this.root, point)?.element
    }

    private pointSearch(node: OptionalNode<E, D, P>, point: P): OptionalNode<E, D, P> {
        while (!!node) {
            if (point[node.dimension] < node.point[node.dimension]) {
                node = node.left
            } else {
                if (this.pointMatch(point, node.point)) {
                    return node
                }
                node = node.right
            }
        }
        return null
    }

    private pointMatch(p1: P, p2: P) {
        for (let i = 0; i < this.dimension_count; i++) {
            if (p1[i] !== p2[i]) {
                return false
            }
        }
        return true
    }
}