import {BoxGeometry, CylinderGeometry, Mesh, MeshLambertMaterial} from 'three'
import Game from '../game'
import Entity from '../entity'
import Movable from './movable'
import {hexy} from '../../util/hexy'
import * as BufferGeometryUtils from 'three/examples/jsm/utils/BufferGeometryUtils'

const top_geometry = new BoxGeometry(0.75, 0.4, 0.4)
top_geometry.translate(0, 0, 0.6)
const arm_geometry = new CylinderGeometry(0.2, 0.2, 1)
arm_geometry.rotateX(0.5 * Math.PI)
const hammer_geometry = BufferGeometryUtils.mergeBufferGeometries([top_geometry, arm_geometry], true)

const top_material = new MeshLambertMaterial({color: '#555555'})
const arm_material = new MeshLambertMaterial({color: '#693A02'})
const hammer_materials = [top_material, arm_material]

export default class Hammer extends Entity {
    private movable: Movable
    private model: Mesh

    constructor(public name: string,
                i: number,
                j: number,
                game: Game) {
        super(game)

        this.model = new Mesh(hammer_geometry, hammer_materials)
        this.model.castShadow = true
        this.model.userData.selectable = this

        this.movable = new Movable(this.model, i, j, this, 1)
    }

    init() {
        this.movable.init()
    }

    animate() {
        this.movable.animate()
        if (this.movable.path_index < this.movable.path.length) {
            const {i, j} = this.movable.path[this.movable.path_index]
            const {hx, hy} = hexy(i, j)
            this.model.lookAt(hx, hy, this.model.position.z)
        }
    }

    tick() {
        this.movable.checkMove()
        if (this.movable.move_i === this.movable.i && this.movable.move_j === this.movable.j) {
            this.movable.setMoveTarget(Math.floor(Math.random() * 60) - 30, Math.floor(Math.random() * 60) - 30)
        }
    }
}