import Shard from './entities/shard'
import Sun from './entities/sun'
import Game from './game'
import Terrain from './terrain'
import {Color} from 'three/src/math/Color'
import Entity, {EntityPoint} from './entity'
import KDTree from '../util/kd_tree'
import OakTree from './entities/oak_tree'
import {ij_offsets} from '../util/hexy'
import PineTree from './entities/pine_tree'
import Hammer from './entities/hammer'

export default class World {
    terrain: Terrain

    shards: Shard[]
    target_shard: Shard

    private entities: Entity[] = []
    private entity_tree = new KDTree<Entity, 2, EntityPoint>(2)

    constructor(private game: Game) {
        this.shards = [
            new Shard('Gree', 0, 0, new Color('#009900'), game),
            new Shard('Blue', 2, 1, new Color('#5555FF'), game),
            new Shard('Red', 2, -3, new Color('#990000'), game),
            new Shard('Purp', -2, -1, new Color('#630099'), game),
            new Shard('Yell', -2, 3, new Color('#ffeb00'), game),
        ]
        this.target_shard = this.shards[0]

        this.entities.push(new Sun(game))
        this.terrain = new Terrain(game)
        this.entities.push(this.terrain)
        for (let shard of this.shards) {
            this.entities.push(shard)
        }

        for (let d = 10; d <= 48; d += 8) {
            const offsets = ij_offsets(d)
            const exclude_offsets_i = new Set<number>()
            while (exclude_offsets_i.size < Math.max(2, offsets.length / 20)) {
                exclude_offsets_i.add(Math.floor(Math.random() * offsets.length))
            }
            for (let offset_i = 0; offset_i < offsets.length; offset_i++) {
                if (exclude_offsets_i.has(offset_i)) {
                    continue
                }
                const {i, j} = offsets[offset_i]
                if (Math.random() <= 0.5) {
                    this.entities.push(new OakTree(`Oak Tree`, i, j, game))
                } else {
                    this.entities.push(new PineTree('Pine Tree', i, j, game))
                }
            }
        }

        let odd = false
        for (let {i, j} of ij_offsets(22)) {
            if (odd) {
                this.entities.push(new Hammer(`Hammer`, i, j, game))
            }
            odd = !odd
        }

        game.events.select_shard_index.on(e => this.selectShardIndex(e))
        game.events.select_shard.on(e => this.selectShard(e))
        game.events.move_shard.on(({i, j}) => this.moveShard(i, j))
    }

    init() {
        for (let entity of this.entities) {
            entity.init()
        }
    }

    animate() {
        for (let entity of this.entities) {
            entity.animate()
        }
    }

    tick() {
        for (let entity of this.entities) {
            entity.tick()
        }
    }

    removeEntityLocation(point: EntityPoint, entity: Entity) {
        this.entity_tree.remove(point, entity)
    }

    addEntityLocation(point: EntityPoint, entity: Entity) {
        this.entity_tree.insert(point, entity)
    }

    isLocationOccupied(point: EntityPoint) {
        return !!this.entity_tree.getElementAt(point)
    }

    private selectShardIndex(index: number) {
        if (index < this.shards.length) {
            this.target_shard = this.shards[index]
        }
    }

    private selectShard(shard: Shard) {
        this.target_shard = shard
    }

    private moveShard(i: number, j: number) {
        this.target_shard.movable.setMoveTarget(i, j)
    }
}