import {BufferAttribute, BufferGeometry, Mesh, MeshLambertMaterial} from 'three'
import {hex_points, hex_points_indices, hexy, yxeh} from '../../util/hexy'
import {Vector3} from 'three/src/math/Vector3'
import Game from '../game'

const terrain_hover_material = new MeshLambertMaterial({color: '#FFFFFF', opacity: 0.25, transparent: true})

export default class TerrainHover {
    private vertices: Float32Array
    private position_attribute: BufferAttribute
    model: Mesh

    constructor(private game: Game) {
        const vertices: number[] = []
        for (let i = 0; i < hex_points_indices.length; i++) {
            const hex_point = hex_points[hex_points_indices[i]]
            const hex_x = hex_point.x
            const hex_y = hex_point.y
            vertices.push(hex_x, hex_y, 0)
        }
        this.vertices = new Float32Array(vertices)
        const geometry = new BufferGeometry()
        this.position_attribute = new BufferAttribute(this.vertices, 3)
        geometry.setAttribute('position', this.position_attribute)
        geometry.computeVertexNormals()
        this.model = new Mesh(geometry, terrain_hover_material)
        this.model.position.set(0, 0, 0)
    }

    update(point: Vector3) {
        const {i, j} = yxeh(point.x, point.y)
        const {hx, hy} = hexy(i, j)
        for (let i = 0; i < hex_points_indices.length; i++) {
            const hex_point = hex_points[hex_points_indices[i]]
            const hex_x = hex_point.x + hx
            const hex_y = hex_point.y + hy
            this.vertices[i * 3 + 2] = this.game.world.terrain.getHeight(hex_x, hex_y) + 0.02
        }
        this.position_attribute.needsUpdate = true
        this.model.position.set(hx, hy, 0)
        this.model.visible = true
    }
}