import {CylinderGeometry, Mesh, MeshLambertMaterial} from 'three'
import * as BufferGeometryUtils from 'three/examples/jsm/utils/BufferGeometryUtils.js'
import Game from '../game'
import Entity from '../entity'
import Movable from './movable'
import {z_axis} from '../../util/axis'


const geometries = []
const needle_geometries = []

const trunk_height = 7.5
const trunk_geometry = new CylinderGeometry(0.1, 0.3, trunk_height, 6)
trunk_geometry.translate(0, trunk_height / 2, 0)
trunk_geometry.rotateX(Math.PI / 2)
geometries.push(trunk_geometry)

const needle_length = 0.1

for (let branch_level = 1.5; branch_level <= trunk_height; branch_level += 0.5) {
    const branch_length = (trunk_height - branch_level) / 3 + 0.5
    const branch_count = 10
    const rotate_offset = Math.random() * Math.PI * 2
    for (let bn = 0; bn < branch_count; bn++) {
        const branch_geometry = new CylinderGeometry(0.01, 0.1, branch_length, 3)
        branch_geometry.translate(0, branch_length / 2, 0)
        branch_geometry.rotateX(Math.PI / 6)
        branch_geometry.translate(0, 0, branch_level)
        branch_geometry.rotateZ(Math.PI * 2 * bn / branch_count + rotate_offset)
        geometries.push(branch_geometry)

        const needle_geometry = new CylinderGeometry(0.00, 0.02, needle_length, 3)
        needle_geometry.translate(0, needle_length * 10, 0)
        needle_geometry.rotateX(Math.PI / 6)
        needle_geometry.translate(0, 0, branch_level)
        needle_geometry.rotateZ(Math.PI * 2 * bn / branch_count + rotate_offset)
        needle_geometries.push(needle_geometry)
    }
}

const tree_geometry = BufferGeometryUtils.mergeBufferGeometries(geometries)
const needles_geometry = BufferGeometryUtils.mergeBufferGeometries(needle_geometries)

const material = new MeshLambertMaterial({color: '#705100'})
const needles_material = new MeshLambertMaterial({color: '#bea45a'})

const bounce_duration = 250
const needles_duration = 1000
const ticks_between_attacks = 20

export default class PineTree extends Entity {
    private movable: Movable
    private model: Mesh
    private needles_model: Mesh
    private next_attack_tick = 0
    private bounce_start_time = 0

    constructor(public name: string,
                i: number,
                j: number,
                game: Game) {
        super(game)

        const rotation = Math.random() * Math.PI * 2

        this.model = new Mesh(tree_geometry, material)
        this.model.castShadow = true
        this.model.userData.selectable = this
        this.model.rotateOnWorldAxis(z_axis, rotation)

        this.needles_model = new Mesh(needles_geometry, needles_material)
        this.needles_model.visible = false
        this.needles_model.rotateOnWorldAxis(z_axis, rotation)

        this.movable = new Movable(this.model, i, j, this, -1)
    }

    init() {
        this.movable.init()
        this.needles_model.position.copy(this.model.position)
        this.game.events.register_object.emit(this.needles_model)
        this.next_attack_tick = this.game.current_tick + Math.floor(Math.random() * ticks_between_attacks)
    }

    animate() {
        this.movable.animate()

        if (this.bounce_start_time + bounce_duration > this.game.current_time) {
            const bounce_time = this.game.current_time - this.bounce_start_time
            const factor = 1 + (bounce_duration / 2 - Math.abs(bounce_time - bounce_duration / 2)) / bounce_duration
            this.model.scale.set(factor, factor, 1)
        } else {
            this.model.scale.set(1, 1, 1)
        }

        if (this.bounce_start_time + needles_duration > this.game.current_time) {
            this.needles_model.visible = true
            const bounce_time = this.game.current_time - this.bounce_start_time
            const factor = 1 + bounce_time / needles_duration * 5
            this.needles_model.scale.set(factor, factor, 1)
        } else {
            this.needles_model.visible = false
        }
    }

    tick() {
        if (this.next_attack_tick <= this.game.current_tick) {
            this.bounce_start_time = this.game.current_time
            this.next_attack_tick += ticks_between_attacks
        }
    }
}