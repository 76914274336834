import React from 'react'
import Shard from '../game/entities/shard'
import Game from '../game/game'


export class DnfwsUi extends React.Component<{ game: Game }> {
    selectShard(event: React.MouseEvent, shard: Shard) {
        this.props.game.events.select_shard.emit(shard)
        event.stopPropagation()
    }

    render() {
        const shard_buttons = this.props.game.world.shards.map((shard, i) => (
            <button key={i}
                    type="button"
                    className={`btn m-1 btn-shard-${i}`}
                    onClick={event => this.selectShard(event, shard)}
                    onMouseDown={event => event.stopPropagation()}
                    onMouseUp={event => event.stopPropagation()}
                    onMouseMove={event => event.stopPropagation()}>
                {shard.name}
            </button>
        ))
        return (
            <div id="dnfws-ui" className="d-flex">
                <div id="main-left" className="flex-grow-1 d-flex flex-column">
                    <div id="top-status">
                        {/*<h1 className="text-center">Top Status</h1>*/}
                    </div>
                    <div className="flex-grow-1 d-flex">
                        <div id="left-status" className="d-flex flex-column justify-content-center">
                            {shard_buttons}
                        </div>
                        <div id="middle" className="flex-grow-1">
                            {/*<h1 className="text-center">Middle Stuff</h1>*/}
                        </div>
                    </div>
                    <div id="bottom">
                        {/*<h1 className="text-center">Bottom Status</h1>*/}
                    </div>
                </div>
                <div id="main-right" className="d-flex flex-column justify-content-between">
                    <div>
                        {/*<img src={minimap}/>*/}
                    </div>
                    <div>
                        {/*<img src={inventory}/>*/}
                    </div>
                </div>
            </div>
        )
    }
}