import {ij_distance, ij_offsets} from './hexy'
import World from '../game/world'
import MinHeap from './heap'

export type IJ = { i: number, j: number }

class IJNode {
    from_node?: IJNode
    closed = false
    score = 0

    constructor(public i: number,
                public j: number,
                public to_score: number,
                public from_score: number,
                public occupied: boolean) {
    }

    update_to_score(v: number) {
        this.to_score = v
        this.score = v + this.from_score
    }
}

function pathKey(i: number, j: number): string {
    return i + ',' + j
}

function reconstructPath(node: IJNode) {
    const path: IJ[] = [{i: node.i, j: node.j}]
    while (node.from_node) {
        node = node.from_node
        path.splice(0, 0, {i: node.i, j: node.j})
    }
    return path
}

function compareNodes(a: IJNode, b: IJNode) {
    const d1 = a.score
    const d2 = b.score
    if (d1 < d2) {
        return -1
    } else if (d1 > d2) {
        return 1
    } else {
        return 0
    }
}

const node_cache: IJNode[] = []
let node_cache_index = 0

function getNode(world: World, i: number, j: number, to_score: number, target_i: number, target_j: number) {
    let node: IJNode
    if (node_cache_index < node_cache.length) {
        node = node_cache[node_cache_index]
        node.i = i
        node.j = j
        node.from_score = ij_distance(i, j, target_i, target_j)
        node.update_to_score(to_score)
        node.occupied = world.isLocationOccupied([i, j])
        node.from_node = undefined
        node.closed = false
    } else {
        node = new IJNode(i, j, to_score, ij_distance(i, j, target_i, target_j), world.isLocationOccupied([i, j]))
        node_cache.push(node)
    }
    node_cache_index++
    return node
}

const node_map = new Map<string, IJNode>()
const open_set = new MinHeap<IJNode>(compareNodes)

export function findPath(world: World, source_i: number, source_j: number, target_i: number, target_j: number) {
    node_cache_index = 0
    node_map.clear()
    open_set.clear()

    let node = getNode(world, source_i, source_j, 0, target_i, target_j)
    let nearest_node = node
    node_map.set(pathKey(node.i, node.j), node)
    open_set.insert(node)

    while (open_set.size() > 0) {
        node = open_set.extractMinimum()!!
        node.closed = true
        if (node.i === target_i && node.j === target_j) {
            break
        }
        if (node.to_score > 50) {
            break
        }
        for (let offset of ij_offsets(1)) {
            const neighbor_i = node.i + offset.i
            const neighbor_j = node.j + offset.j
            const neighbor_key = pathKey(neighbor_i, neighbor_j)
            const tentative_score = node.to_score + 1
            let neighbor_node = node_map.get(neighbor_key)
            const visited = !!neighbor_node
            if (!neighbor_node) {
                neighbor_node = getNode(world, neighbor_i, neighbor_j, tentative_score, target_i, target_j)
                neighbor_node.from_node = node
                node_map.set(neighbor_key, neighbor_node)
            } else if (tentative_score < neighbor_node.to_score) {
                neighbor_node.from_node = node
                neighbor_node.update_to_score(tentative_score)
            }
            if (neighbor_node.closed || neighbor_node.occupied) {
                continue
            }
            if (nearest_node.from_score > neighbor_node.from_score) {
                nearest_node = neighbor_node
            }
            if (visited) {
                open_set.floatUpElement(neighbor_node)
            } else {
                open_set.insert(neighbor_node)
            }
        }
    }
    return reconstructPath(nearest_node)
}
