import {Events} from '../managers/events'
import World from './world'
import CameraManager from '../managers/camera_manager'
import SceneManager from '../managers/scene_manager'
import Selections from '../managers/selections'
import RendererManager from '../managers/renderer_manager'
import Controls from '../managers/controls'
import {Stats} from '../util/stats'

export default class Game {
    current_time = performance.now()
    previous_time = this.current_time
    elapsed_time = 0

    last_tick_time = 0
    current_tick = 0
    readonly tick_duration = 100

    events = new Events()
    world = new World(this)
    private stats = new Stats()

    scene_manager = new SceneManager(this)
    camera_manager = new CameraManager(this)
    selections = new Selections(this)
    renderer_manager = new RendererManager(this)
    controls = new Controls(this)

    start() {
        this.controls.init()
        this.world.init()
        this.selections.init()
        this.renderer_manager.init()

        document.getElementById('stats')?.appendChild(this.stats.dom)

        this.current_time = performance.now()
        this.previous_time = this.current_time
        this.elapsed_time = 0
        this.last_tick_time = this.current_time
        this.current_tick = 0

        requestAnimationFrame(() => this.animate())
    }

    private animate() {
        this.stats.update()

        this.previous_time = this.current_time
        this.current_time = performance.now()
        this.elapsed_time = this.current_time - this.previous_time

        this.world.animate()
        this.camera_manager.update(this.world.target_shard.model.position)
        this.selections.update()
        this.renderer_manager.render()

        while (this.last_tick_time < this.current_time - this.tick_duration) {
            this.last_tick_time += this.tick_duration
            this.current_tick += 1
            this.world.tick()
        }

        requestAnimationFrame(() => this.animate())
    }

    resetStats() {
        this.stats.reset()
    }
}