import {Vector3} from 'three'
import Game from '../game/game'


export default class Vector3ConstantRatioAnimation {
    position: Vector3
    target: Vector3

    constructor(x: number, y: number, z: number, public ratio: number, private game: Game) {
        this.position = new Vector3(x, y, z)
        this.target = this.position.clone()
    }

    set(x: number, y: number, z: number) {
        this.position.set(x, y, z)
    }

    setTarget(x: number, y: number, z: number) {
        this.target.set(x, y, z)
    }

    animate() {
        const elapsed_time = Math.min(this.game.elapsed_time, 100)
        this.position.set(
            this.position.x + this.ratio * elapsed_time / 1000 * (this.target.x - this.position.x),
            this.position.y + this.ratio * elapsed_time / 1000 * (this.target.y - this.position.y),
            this.position.z + this.ratio * elapsed_time / 1000 * (this.target.z - this.position.z)
        )
    }
}