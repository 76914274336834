import {Object3D} from 'three'
import Shard from '../game/entities/shard'

class EventHandler<E> {
    private listeners: ((e: E) => void)[] = []

    emit(event: E) {
        for (let listener of this.listeners) {
            listener(event)
        }
    }

    on(callback: (e: E) => void) {
        this.listeners.push(callback)
        return () => {
            const i = this.listeners.indexOf(callback)
            if (i >= 0) {
                this.listeners.splice(i, 1)
            }
        }
    }
}

export class Events {
    resize = new EventHandler<{ width: number, height: number }>()

    mouse_move = new EventHandler<MouseEvent>()
    mouse_out = new EventHandler<MouseEvent>()
    mouse_down = new EventHandler<MouseEvent>()
    mouse_up = new EventHandler<MouseEvent>()
    mouse_middle_drag = new EventHandler<MouseEvent>()
    mouse_wheel = new EventHandler<WheelEvent>()

    select_shard_index = new EventHandler<number>()
    select_shard = new EventHandler<Shard>()
    move_shard = new EventHandler<{ i: number, j: number }>()

    register_object = new EventHandler<Object3D>()
    unregister_object = new EventHandler<Object3D>()
}