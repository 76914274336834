import Game from '../game/game'
import debounce from '../util/debounce'

export default class Controls {
    private middle_dragging = false

    constructor(private game: Game) {
    }

    init() {
        window.addEventListener('mousemove', (event: MouseEvent) => {
            if (this.middle_dragging) {
                this.game.events.mouse_middle_drag.emit(event)
            }
            this.game.events.mouse_move.emit(event)
        }, false)

        window.addEventListener('mouseout', (event: MouseEvent) => {
            this.game.events.mouse_out.emit(event)
        }, false)

        window.addEventListener('mousedown', (event: MouseEvent) => {
            if (event.button === 0) {
                this.game.events.mouse_down.emit(event)
            } else if (event.button === 1) {
                this.middle_dragging = true
            }
        }, false)

        window.addEventListener('mouseup', (event: MouseEvent) => {
            if (event.button === 0) {
                this.game.events.mouse_up.emit(event)
            } else if (event.button === 1) {
                this.middle_dragging = false
            }
        }, false)

        window.addEventListener('wheel', (event: WheelEvent) => {
            this.game.events.mouse_wheel.emit(event)
        }, false)

        window.addEventListener('keydown', (event: KeyboardEvent) => {
            if (event.altKey) {
                if (event.key === 'p') {
                    this.game.resetStats()
                }
            } else if (event.ctrlKey) {
            } else if (event.shiftKey) {
            } else if (event.metaKey) {
            } else {
                if (event.key === '1' || event.key === '2' || event.key === '3' || event.key === '4' || event.key === '5') {
                    const num = parseInt(event.key, 10) - 1
                    this.game.events.select_shard_index.emit(num)
                }
            }
        }, false)

        window.addEventListener('resize', debounce(() => {
            const width = window.innerWidth
            const height = window.innerHeight
            this.game.events.resize.emit({width, height})
        }, 100), false)
    }
}