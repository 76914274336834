import Shard from '../game/entities/shard'

export function updateShardStyles(shards: Shard[]) {
    let styles = document.getElementById('shard-styles')
    if (!styles) {
        styles = document.createElement('style')
        styles.id = 'shard-styles'
        const header = document.getElementsByTagName('head')[0]
        header.appendChild(styles)
    }

    styles.innerHTML = shards.map((shard, i) => `
.btn-shard-${i} {
    color: #fff;
    background-color: #${shard.color.getHexString()};
}
.btn-shard-${i}:hover {
    color: #fff;
    background-color: #${shard.color.clone().multiplyScalar(0.8).getHexString()};
}
`).join('')
}