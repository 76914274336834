import {Vector3} from 'three'
import Game from '../game/game'


export default class Vector3LinearTimeAnimation {
    position: Vector3
    target: Vector3
    origin: Vector3
    start_time = 0
    duration = 0
    animating = false

    constructor(x: number, y: number, z: number, private game: Game) {
        this.position = new Vector3(x, y, z)
        this.target = this.position.clone()
        this.origin = this.position.clone()
    }

    set(x: number, y: number, z: number) {
        this.position.set(x, y, z)
        this.animating = false
    }

    setTarget(x: number, y: number, z: number, duration: number) {
        this.target.set(x, y, z)
        this.origin.copy(this.position)
        this.start_time = this.game.current_time
        this.duration = duration
        this.animating = true
    }

    animate() {
        if (this.animating) {
            if (this.game.current_time > this.start_time + this.duration) {
                this.position.copy(this.target)
                this.animating = false
            } else {
                const time_per = (this.game.current_time - this.start_time) / this.duration
                this.position.set(
                    this.origin.x + time_per * (this.target.x - this.origin.x),
                    this.origin.y + time_per * (this.target.y - this.origin.y),
                    this.origin.z + time_per * (this.target.z - this.origin.z)
                )
            }
            return true
        } else {
            return false
        }
    }
}