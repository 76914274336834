import Game from './game'

export type EntityPoint = [number, number]

export default abstract class Entity {
    constructor(public game: Game) {
    }

    abstract init(): void

    abstract animate(): void

    abstract tick(): void
}